import React from "react";
import axios from "axios";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { BsChevronRight, BsChevronLeft } from "react-icons/bs";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import SwiperCore, {
  Navigation,
  Pagination,
  Controller,
  Autoplay,
} from "swiper/core";
// install Swiper modules
SwiperCore.use([Navigation, Pagination, Controller, Autoplay]);

const Release = ({ channelId }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [id, setId] = React.useState("");

  const [swiper, setSwiper] = React.useState();
  const prevRef = React.useRef();
  const nextRef = React.useRef();
  // YOUTUBE FETCHING
  const [youtubeVideos, setYoutubeVideos] = React.useState([]);

  const key = "AIzaSyAjaijdDKYqnbaYLfJx2UavasGgBqu-QPc";
  const ResultCount = 10;

  const youTubeAPI = `https://www.googleapis.com/youtube/v3/search?key=${key}&channelId=${channelId}&part=snippet,id&order=date&maxResults=${ResultCount}`;

  React.useEffect(() => {
    const dataFetch = async () => {
      await axios
        .get(youTubeAPI)
        .then((res) => {
          setYoutubeVideos(res.data.items);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    dataFetch();
  }, [youTubeAPI]);

  // SWIPER
  React.useEffect(() => {
    if (swiper) {
      swiper.params.navigation.prevEl = prevRef.current;
      swiper.params.navigation.nextEl = nextRef.current;
      swiper.navigation.init();
      swiper.navigation.update();
    }
  }, [swiper]);

  React.useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isOpen]);

  const Player = ({ setIsOpen }) => {
    return (
      <div className="justify-center items-center backdrop-blur flex fixed inset-0 z-50 ">
        <div className="mainContainer ">
          <div
            className=" absolute bg-white bg-opacity-30 rounded-xl p-2 text-white right-2 top-2 hover:bg-blue-500 cursor-pointer"
            onClick={() => {
              setIsOpen(!isOpen);
              setId("");
            }}
          >
            Closes
          </div>

          {/* YOUTUBE */}

          <iframe
            src={`https://www.youtube.com/embed/${id}`}
            className="w-screen md:w-[700px] lg:w-[1024px]"
            height="520px"
            frameborder="0"
            title="Youtube"
            allowtransparency="true"
            allowfullscreen
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          />
        </div>
      </div>
    );
  };

  return (
    <div
      className=" w-screen flex  flex-col items-center py-10 bg-gradient-to-t from-black to-[#000732] "
      id="release"
    >
      {isOpen && (
        <Player
          // sets
          setIsOpen={setIsOpen}
        />
      )}
      <div
        className="font-thin w-full gap-x-2 flex flex-col lg:flex-row justify-center  xl:text-7xl text-3xl py-8 text-white"
        data-aos="fade-up"
      >
        <div className="text-white mx-2">Latest Release </div>
      </div>
      <div className=" w-full lg:w-[1024px] xl:max-w-[1440px]">
        <div className="w-full  flex">
          <div
            className="swiper-button hidden md:flex cursor-pointer items-center "
            ref={prevRef}
            style={{
              height: 200,
            }}
          >
            <div className="h-10 w-10 flex text-white hover:text-blue-500 hover:border-blue-500 hover:bg-blue-500 hover:bg-opacity-30 items-center justify-center bg-white rounded-full mx-2 bg-opacity-20 border border-white border-opacity-30">
              <BsChevronLeft />
            </div>
          </div>
          <Swiper
            modules={[Navigation, Pagination]}
            slidesPerView={1}
            spaceBetween={20}
            updateOnWindowResize
            autoplay={{ delay: 3000 }}
            loop={true}
            navigation={{
              prevEl: prevRef?.current,
              nextEl: nextRef?.current,
            }}
            observer
            observerParents
            breakpoints={{
              640: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
              1440: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
            }}
            onSwiper={setSwiper}
            className="px-10 "
          >
            {youtubeVideos.map((item, i) => (
              <SwiperSlide key={item.id.videoId}>
                <div
                  className=" flex flex-col rounded-xl overflow-hidden"
                  onClick={() => {
                    setId(item.id.videoId);
                    setIsOpen(true);
                  }}
                >
                  <div>
                    <img
                      alt="product_image"
                      src={item.snippet.thumbnails.high.url} // use normal <img> attributes as props
                      style={{
                        width: "100%",
                        height: "200px",
                      }}
                      className="object-cover cursor-pointer h-[480px] pic"
                    />
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <div
            className="swiper-button hidden md:flex cursor-pointer  items-center"
            ref={nextRef}
            style={{
              height: 200,
            }}
          >
            <div className="h-10 w-10  hover:text-blue-500 hover:border-blue-500 hover:bg-blue-500 hover:bg-opacity-30 flex text-white items-center justify-center bg-white rounded-full mx-2 bg-opacity-20 border border-white border-opacity-30">
              <BsChevronRight />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Release;
