import React from "react";
import Slide from "../components/homeSlide";
import NavBar from "../components/navbar";
import Release from "../components/release";
import Footer from "../components/footer";
import About from "../components/about";
import Gallery from "../components/gallery";

const Home = () => {
  return (
    <div className="w-full overflow-hidden">
      <NavBar />
      <Slide />
      <About />
      <Gallery />
      <Release channelId="UCE44KnO8KRiv5DQAxfXpZuA" />
      <Footer />
    </div>
  );
};

export default Home;
