import React, { useEffect, useState } from "react";
import { Link } from "react-scroll";
import { CgMenuRightAlt } from "react-icons/cg";

const NavBar = () => {
  const [navScroll, setNavScroll] = useState(true);
  const [expand, setExpand] = useState(false);

  useEffect(() => {
    window.onscroll = () => {
      if (window.scrollY <= 0) {
        setNavScroll(true);
      } else {
        setNavScroll(true);
      }
    };
  }, []);
  return (
    <div
      className={`w-screen border-gray-600 border-t fixed top-0  px-4 py-2 z-10 backdrop-filter ${
        navScroll && "backdrop-blur"
      }  flex sm:justify-start md:justify-between `}
    >
      <Link
        className=" px-2 text-white object-cover cursor-pointer"
        to="home"
        duration={500}
        smooth={true}
        offset={-70}
        onClick={() => setExpand(false)}
      >
        <b>MZUNGU</b> TYGA
      </Link>

      {/* opening navigation button */}
      {!expand && (
        <div
          className=" sm:hidden cursor-pointer  absolute right-0 text-white mx-4"
          onClick={() => {
            setExpand(true);
          }}
        >
          <CgMenuRightAlt size={27} />
        </div>
      )}
      {/* opening navigation button */}

      {/* mobile navigations */}
      <div
        className={
          expand
            ? "flex flex-col transition-all duration-500 sm:hidden w-screen items-start absolute  -mt-10 p-10 bg-black  text-white"
            : "h-0 overflow-hidden bg-black"
        }
      >
        <div
          className="py-2 cursor-pointer mt-10 "
          onClick={() => {
            setExpand(false);
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className={
              expand ? "h-6 w-6 transition-all duration-500 " : "h-6 w-6 "
            }
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </div>

        <Link
          className="flex text-sm group px-2 flex-col cursor-pointer group hover:text-orange-500 hover:"
          to="home"
          duration={500}
          smooth={true}
          offset={-70}
          onClick={() => setExpand(false)}
        >
          <div
            className={
              expand ? "  transition-all duration-500 delay-300" : " flex"
            }
          >
            HOME
          </div>
          <div
            className="bg-gray-200 w-0 transition-all group-hover:w-full"
            style={{
              height: 2,
            }}
          />
        </Link>
        <Link
          className="flex text-sm group px-2 flex-col cursor-pointer group hover:text-orange-500 hover:"
          to="about"
          duration={500}
          smooth={true}
          offset={-70}
          onClick={() => setExpand(false)}
        >
          <div
            className={
              expand ? "  transition-all duration-500 delay-500" : " flex"
            }
          >
            ABOUT
          </div>
          <div
            className="bg-gray-200 w-0 transition-all group-hover:w-full"
            style={{
              height: 2,
            }}
          />
        </Link>

        <Link
          className="flex text-sm group px-2 flex-col cursor-pointer group hover:text-orange-500 hover:"
          to="gallery"
          duration={500}
          smooth={true}
          offset={-70}
          onClick={() => setExpand(false)}
        >
          <div
            className={
              expand ? "  transition-all duration-500 delay-1000" : " flex"
            }
          >
            GALLERY
          </div>
          <div
            className="bg-gray-200 w-0 transition-all group-hover:w-full"
            style={{
              height: 2,
            }}
          />
        </Link>
        <Link
          className="flex text-sm group px-2 flex-col cursor-pointer group hover:text-orange-500 hover:"
          to="release"
          duration={500}
          smooth={true}
          offset={-70}
          onClick={() => setExpand(false)}
        >
          <div
            className={
              expand ? "  transition-all duration-500 delay-700" : " flex"
            }
          >
            WORK
          </div>
          <div
            className="bg-gray-200 w-0 transition-all group-hover:w-full"
            style={{
              height: 2,
            }}
          />
        </Link>
      </div>
      {/* mobile navigations */}

      {/* desktop navigations */}
      <div
        className={
          navScroll
            ? "flex hidden sm:block text-white px-2"
            : "flex hidden sm:block tex-black px-2 "
        }
      >
        <div className="flex items-center ">
          <Link
            className="flex text-sm group px-2 flex-col cursor-pointer group hover:text-orange-500 hover:"
            to="home"
            duration={500}
            smooth={true}
            offset={0}
            onClick={() => setExpand(false)}
          >
            <div
              className={
                expand ? "  transition-all duration-500 delay-300" : " flex"
              }
            >
              HOME
            </div>
            <div
              className="bg-gray-200 w-0 transition-all group-hover:w-full"
              style={{
                height: 2,
              }}
            />
          </Link>
          <Link
            className="flex text-sm group px-2 flex-col cursor-pointer group hover:text-orange-500 hover:"
            to="about"
            duration={500}
            smooth={true}
            offset={10}
            onClick={() => setExpand(false)}
          >
            <div
              className={
                expand ? "  transition-all duration-500 delay-500" : " flex"
              }
            >
              ABOUT
            </div>
            <div
              className="bg-gray-200 w-0 transition-all group-hover:w-full"
              style={{
                height: 2,
              }}
            />
          </Link>

          <Link
            className="flex text-sm group px-2 flex-col cursor-pointer group hover:text-orange-500 hover:"
            to="gallery"
            duration={500}
            smooth={true}
            offset={0}
            onClick={() => setExpand(false)}
          >
            <div
              className={
                expand ? "  transition-all duration-500 delay-1000" : " flex"
              }
            >
              GALLERY
            </div>
            <div
              className="bg-gray-200 w-0 transition-all group-hover:w-full"
              style={{
                height: 2,
              }}
            />
          </Link>
          <Link
            className="flex text-sm group px-2 flex-col cursor-pointer group hover:text-orange-500 hover:"
            to="release"
            duration={500}
            smooth={true}
            offset={10}
            onClick={() => setExpand(false)}
          >
            <div
              className={
                expand ? "  transition-all duration-500 delay-700" : " flex"
              }
            >
              WORK
            </div>
            <div
              className="bg-gray-200 w-0 transition-all group-hover:w-full"
              style={{
                height: 2,
              }}
            />
          </Link>
        </div>
      </div>
      {/* desktop navigations */}
    </div>
  );
};

export default NavBar;
