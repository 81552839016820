import React, { useState, useEffect } from "react";

const Slide = () => {
  const [firstAnime, setFirstAnime] = useState(false);

  useEffect(() => {
    setInterval(() => {
      setTimeout(() => {
        setFirstAnime(true);
      }, 300);
      setTimeout(() => {
        setFirstAnime(false);
      }, 5000);
    }, 6000);
  }, []);

  return (
    <div className="" id="home">
      <div className="mySwiper">
        {/* for desktop */}
        <div className="relative hidden md:block">
          <img
            src="https://blogger.googleusercontent.com/img/a/AVvXsEh47ShDY7-GdRmQtAmBG8A62u5YXxohz5IIVe2kqYxCv2_bTw7sMNcvexwLxHcFLIlTNWpkKp1tKcs_73eNpgLxe2Lg7aIdxCPzLISqpwCWutNSkDiK1Z-DIvKQr7Lmc2w0LfeUHK0GzkgT0H5Z48-fXdTlhYE-dEQI1GeNPkVzZLnPb4Vv-JJO2MxpqNg=s16000"
            alt="thumbnail"
            style={{ height: "100vh", width: "100%", objectFit: "cover" }}
          />
          <div className="w-screen flex flex-col items-center md:pl-10 bg-gradient-to-b from-transparent from-opacity-0 to-[#000732]  justify-end pb-20  h-screen absolute top-0">
            <div className="md:w-1/2 w-full  px-4 md:mx-0 flex flex-col items-center   justify-center">
              <div className=" overflow-hidden text-center">
                <div
                  className="font-black black text-5xl text-gray-300 play"
                  style={{
                    transition: ".5s",
                    transform: firstAnime
                      ? "translateY(0px)"
                      : "translateY(100px)",
                  }}
                >
                  MZUNGU TYGA
                </div>
              </div>

              <div
                className="flex justify-between items-center text-white mb-5 mt-4"
                style={{
                  width: !firstAnime ? 0 : 200,
                  transition: ".5s",
                }}
              >
                <div
                  className=" w-full"
                  style={{
                    background: "#687477",
                    height: 2,
                  }}
                />
                <div className=" w-full bg-blue-600 h-[2px]" />
                <div
                  className=" w-full "
                  style={{
                    background: "#403234",
                    height: 2,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        {/* for desktop */}
        {/* for mobile */}
        <div className="relative md:hidden">
          <img
            src="https://blogger.googleusercontent.com/img/a/AVvXsEh47ShDY7-GdRmQtAmBG8A62u5YXxohz5IIVe2kqYxCv2_bTw7sMNcvexwLxHcFLIlTNWpkKp1tKcs_73eNpgLxe2Lg7aIdxCPzLISqpwCWutNSkDiK1Z-DIvKQr7Lmc2w0LfeUHK0GzkgT0H5Z48-fXdTlhYE-dEQI1GeNPkVzZLnPb4Vv-JJO2MxpqNg=s16000"
            alt="thumbnail"
            style={{ height: "100vh", width: "100%", objectFit: "cover" }}
          />
          <div
            className="w-screen flex flex-col items-center md:pl-10   justify-end pb-20  h-screen absolute top-0"
            style={{
              background: "linear-gradient(rgba(0,0,0,0), black)",
            }}
          >
            <div className="md:w-1/2 w-full  px-4 md:mx-0 flex flex-col items-center   justify-center">
              <div className=" overflow-hidden text-center">
                <div className="font-black black text-5xl text-gray-300 play">
                  MZUNGU TYGA
                </div>
              </div>

              <div
                className="flex justify-between items-center text-white mb-5 mt-4"
                style={{
                  width: !firstAnime ? 0 : 200,
                  transition: ".5s",
                }}
              >
                <div
                  className=" w-full"
                  style={{
                    background: "#687477",
                    height: 2,
                  }}
                />
                <div
                  className=" w-full "
                  style={{
                    background: "#E2C2B3",
                    height: 2,
                  }}
                />
                <div
                  className=" w-full "
                  style={{
                    background: "#403234",
                    height: 2,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        {/* /for mobile */}
      </div>
    </div>
  );
};

export default Slide;
