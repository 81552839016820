import React from "react";
import { SiAudiomack } from "react-icons/si";
import { BsInstagram, BsYoutube } from "react-icons/bs";
import { FaTiktok, FaTwitter } from "react-icons/fa";
import { Link } from "react-scroll";
const Footer = () => {
  return (
    <div className="py-10 bg-black text-white">
      <div className="flex items-start justify-center grid md:grid-cols-2">
        <div className="w-full flex justify-start ">
          <div className="ml-10">
            <div className="text-left ">
              <div className="text-lg black font-bold uppercase text-left">
                Email
              </div>
              <a
                href="mailto:mzungutyga@gmail.com"
                target="_blank"
                rel="noreferrer"
                className="text-sm my-1"
              >
                mzungutyga@gmail.com
              </a>
            </div>
          </div>
        </div>

        <div className="w-full flex justify-start md:justify-end md:pr-20 pl-10">
          <div>
            <div className=" mt-2 ">
              <div className="text-lg black font-bold uppercase text-left">
                NAVIGATIONS
              </div>
              <div className="text-lg flex flex-col w-full items-start ">
                <Link
                  className="flex text-sm group px-2 flex-col cursor-pointer group hover:text-orange-500 hover:"
                  to="home"
                  duration={500}
                  smooth={true}
                  offset={-70}
                >
                  HOME
                </Link>
                <Link
                  className="flex text-sm group px-2 flex-col cursor-pointer group hover:text-orange-500 hover:"
                  to="about"
                  duration={500}
                  smooth={true}
                  offset={-70}
                >
                  ABOUT
                </Link>
                <Link
                  className="flex text-sm group px-2 flex-col cursor-pointer group hover:text-orange-500 hover:"
                  to="gallery"
                  duration={500}
                  smooth={true}
                  offset={-70}
                >
                  GALLERY
                </Link>
                <Link
                  className="flex text-sm group px-2 flex-col cursor-pointer group hover:text-orange-500 hover:"
                  to="work"
                  duration={500}
                  smooth={true}
                  offset={-70}
                >
                  WORK
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-screen flex justify-center mt-10">
        <div>
          <div className="text-2xl  font-medium">Let us Connect</div>
          <div className="flex flex-wrap p-2">
            <a
              href="https://www.instagram.com/mzungutyga/"
              target="_blank"
              rel="noreferrer"
              className="h-10 w-10 flex m-1 justify-center items-center bg-white bg-opacity-10 rounded-full "
            >
              <BsInstagram size={20} />
            </a>

            <a
              href="https://twitter.com/Mzungutyga "
              target="_blank"
              rel="noreferrer"
              className="h-10 w-10 flex m-1 justify-center items-center bg-white bg-opacity-10 rounded-full "
            >
              <FaTwitter size={20} />
            </a>

            <a
              href="http://tiktok.com/@mzungutyga"
              target="_blank"
              rel="noreferrer"
              className="h-10 w-10 flex m-1 justify-center items-center bg-white bg-opacity-10 rounded-full "
            >
              <FaTiktok size={20} />
            </a>

            <a
              href="https://www.youtube.com/@mzungutyga"
              target="_blank"
              rel="noreferrer"
              className="h-10 w-10 flex m-1 justify-center items-center bg-white bg-opacity-10 rounded-full "
            >
              <BsYoutube size={20} />
            </a>
            <a
              href="https://audiomack.com/mzungutyga"
              target="_blank"
              rel="noreferrer"
              className="h-10 w-10 flex m-1 justify-center items-center bg-white bg-opacity-10 rounded-full "
            >
              <SiAudiomack size={20} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
