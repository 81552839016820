import React from "react";
import { BsInstagram, BsYoutube } from "react-icons/bs";
import { FaTiktok, FaTwitter } from "react-icons/fa";
import { SiAudiomack } from "react-icons/si";
import AOS from "aos";
import "aos/dist/aos.css";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-cards";

// import Swiper core and required modules
import SwiperCore, { EffectCards, Autoplay } from "swiper";

// install Swiper modules
SwiperCore.use([EffectCards, Autoplay]);

const About = () => {
  AOS.init({
    duration: 1000,
  });
  return (
    <div
      className="md:py-24 w-full flex flex-col bg-gradient-to-b from-[#000732] to-black items-center justify-center"
      id="about"
    >
      <div className="w-full md:w-3/4 2xl:w-2/4 text-white">
        {/* mobile version */}
        <div
          align="justify"
          className=" md:mx-0 pb-10 md:hidden lg:pb-0 w-full md:w-2/3 px-4"
          data-aos="fade-up"
        >
          <div
            className="font-bold black text-3xl md:text-5xl lg:py-8 "
            data-aos="zoom-in"
          >
            About Me
          </div>
          Introducing Jan Cep (*1974), also known as JC or Mzungu Tyga, a fresh
          and talented artist in the music industry. JC is an upcoming singer,
          songwriter, multigenre music composer, and multi-instrumentalist. He
          was born into a family of great musicians in the former Czechoslovakia
          and inherited his natural talent and deep love for music from his
          ancestors. Despite his musical passion, JC was not supported in his
          youth of becoming a musician, which caused him to put it aside for
          almost 30 years to pursue a career as an international entrepreneur
          and investor. However, he could not resist his need for artistic
          expression completely.
          <br />
          <br />
          At the age of 21, he bought himself the first grand piano and began
          self-study. At age of 33, JC composed the song "Step into my Room,"
          which took another 15 years to be released to the public. Despite his
          fear of losing his way, JC's intense inner drive and passion for music
          motivated him to finally step into the world of music publicly.
          <br />
          <br />
          Though he was born and raised in Prague, JC released his first song
          ”MAMA” in Africa after a collaboration with prominent professional
          artists. "MAMA," sung in Swahili, expresses his acknowledgement of
          Mothers as a creative life principle and his respect for women all
          over the world. It was also in Africa where he discovered his artistic
          name Mzungu Tyga, which in Swahili language means “White Tiger”.
          <br />
          <br />
          JC's diverse musical talent extends to composing and singing so far in
          8 languages and a rich variety of genres. His appearance mirrors the
          miscellany of his talents. From composing several yet unpublished
          albums and singles, JC is now living his dream. Additionally, JC has
          spent the last decade restoring to life one of the most iconic
          theaters in Europe “KINO 64 U HRADEB”.
          <br />
          <br />
          His wish and passion is to keep shining light on the richness of human
          experience through his performances, projects and collaborations with
          the world's leading artists, while at the same time to provide healthy
          support to budding talented artists regardless of age.
          <br />
          <br />
          He loves to spread joy, create, and inspire others to live their
          dreams.
          <div className="md:w-3/4 w-full px-4 md:px-0 text-white flex flex-col items-start my-4">
            <div className="text-lg font-bold">
              Connect with the <b className="black">Mzungu Tyga</b>
            </div>
            <div
              className="flex flex-wrap my-4 bg-white bg-opacity-10 rounded-2xl p-2"
              data-aos="zoom-out"
            >
              <a
                href="https://www.instagram.com/mzungutyga/"
                target="_blank"
                rel="noreferrer"
                className="h-10 w-10 flex m-1 justify-center items-center bg-white bg-opacity-10 rounded-full "
                data-aos="zoom-in"
              >
                <BsInstagram size={20} />
              </a>

              <a
                href="https://twitter.com/Mzungutyga "
                target="_blank"
                rel="noreferrer"
                className="h-10 w-10 flex m-1 justify-center items-center bg-white bg-opacity-10 rounded-full "
                data-aos="zoom-in"
              >
                <FaTwitter size={20} />
              </a>

              <a
                href="http://tiktok.com/@mzungutyga"
                target="_blank"
                rel="noreferrer"
                className="h-10 w-10 flex m-1 justify-center items-center bg-white bg-opacity-10 rounded-full "
                data-aos="zoom-in"
              >
                <FaTiktok size={20} />
              </a>

              <a
                href="https://www.youtube.com/@mzungutyga"
                target="_blank"
                rel="noreferrer"
                data-aos="zoom-in"
                className="h-10 w-10 flex m-1 justify-center items-center bg-white bg-opacity-10 rounded-full "
              >
                <BsYoutube size={20} />
              </a>
              <a
                href="https://audiomack.com/mzungutyga"
                target="_blank"
                data-aos="zoom-in"
                rel="noreferrer"
                className="h-10 w-10 flex m-1 justify-center items-center bg-white bg-opacity-10 rounded-full "
              >
                <SiAudiomack size={20} />
              </a>
            </div>
          </div>
        </div>
        {/* mobile version */}
        {/* desktop version */}
        <div className=" w-full  ">
          <div
            align="justify"
            className="mx-4 md:mx-0 hidden md:block pb-10 lg:pb-0 w-full lg:max-w-[1020px] px-4"
            data-aos="fade-up"
          >
            <div
              className="font-bold black text-3xl md:text-5xl lg:pb-8 "
              data-aos="fade-up"
            >
              About Me
            </div>
            Introducing Jan Cep (*1974), also known as JC or Mzungu Tyga, a
            fresh and talented artist in the music industry. JC is an upcoming
            singer, songwriter, multigenre music composer, and
            multi-instrumentalist. He was born into a family of great musicians
            in the former Czechoslovakia and inherited his natural talent and
            deep love for music from his ancestors. Despite his musical passion,
            JC was not supported in his youth of becoming a musician, which
            caused him to put it aside for almost 30 years to pursue a career as
            an international entrepreneur and investor. However, he could not
            resist his need for artistic expression completely.
            <br />
            <br />
            At the age of 21, he bought himself the first grand piano and began
            self-study. At age of 33, JC composed the song "Step into my Room,"
            which took another 15 years to be released to the public. Despite
            his fear of losing his way, JC's intense inner drive and passion for
            music motivated him to finally step into the world of music
            publicly.
            <br />
            <br />
            Though he was born and raised in Prague, JC released his first song
            ”MAMA” in Africa after a collaboration with prominent professional
            artists. "MAMA," sung in Swahili, expresses his acknowledgement of
            Mothers as a creative life principle and his respect for women all
            over the world. It was also in Africa where he discovered his
            artistic name Mzungu Tyga, which in Swahili language means “White
            Tiger”.
            <br />
            <br />
            JC's diverse musical talent extends to composing and singing so far
            in 8 languages and a rich variety of genres. His appearance mirrors
            the miscellany of his talents. From composing several yet
            unpublished albums and singles, JC is now living his dream.
            Additionally, JC has spent the last decade restoring to life one of
            the most iconic theaters in Europe “KINO 64 U HRADEB”.
            <br />
            <br />
            His wish and passion is to keep shining light on the richness of
            human experience through his performances, projects and
            collaborations with the world's leading artists, while at the same
            time to provide healthy support to budding talented artists
            regardless of age.
            <br />
            <br />
            He loves to spread joy, create, and inspire others to live their
            dreams.
            <div className="md:w-3/4 w-full px-4 md:px-0 text-white flex flex-col items-start my-4">
              <div className="text-lg font-bold" data-aos="zoom-in">
                Connect with the <b className="black">Mzungu Tyga</b>
              </div>
              <div
                className="flex flex-wrap my-4 bg-white bg-opacity-10 rounded-2xl p-2"
                data-aos="zoom-out"
              >
                <a
                  href="https://www.instagram.com/mzungutyga/"
                  target="_blank"
                  rel="noreferrer"
                  className="h-10 w-10 flex m-1 justify-center items-center bg-white bg-opacity-10 rounded-full "
                  data-aos="zoom-in"
                >
                  <BsInstagram size={20} />
                </a>

                <a
                  href="https://twitter.com/Mzungutyga "
                  target="_blank"
                  rel="noreferrer"
                  className="h-10 w-10 flex m-1 justify-center items-center bg-white bg-opacity-10 rounded-full "
                  data-aos="zoom-in"
                >
                  <FaTwitter size={20} />
                </a>

                <a
                  href="http://tiktok.com/@mzungutyga"
                  target="_blank"
                  rel="noreferrer"
                  className="h-10 w-10 flex m-1 justify-center items-center bg-white bg-opacity-10 rounded-full "
                  data-aos="zoom-in"
                >
                  <FaTiktok size={20} />
                </a>

                <a
                  href="https://www.youtube.com/@mzungutyga"
                  target="_blank"
                  rel="noreferrer"
                  data-aos="zoom-in"
                  className="h-10 w-10 flex m-1 justify-center items-center bg-white bg-opacity-10 rounded-full "
                >
                  <BsYoutube size={20} />
                </a>
                <a
                  href="https://audiomack.com/mzungutyga"
                  target="_blank"
                  data-aos="zoom-in"
                  rel="noreferrer"
                  className="h-10 w-10 flex m-1 justify-center items-center bg-white bg-opacity-10 rounded-full "
                >
                  <SiAudiomack size={20} />
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* desktop version */}
      </div>
    </div>
  );
};

export default About;
