import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { BsChevronRight, BsChevronLeft } from "react-icons/bs";

// Import Swiper styles
import "swiper/css";

import SwiperCore, { Navigation } from "swiper/core";
// install Swiper modules
SwiperCore.use([Navigation]);

const Gallery = () => {
  const [swiper, setSwiper] = React.useState();
  const prevRef = React.useRef();
  const nextRef = React.useRef();
  React.useEffect(() => {
    if (swiper) {
      swiper.params.navigation.prevEl = prevRef.current;
      swiper.params.navigation.nextEl = nextRef.current;
      swiper.navigation.init();
      swiper.navigation.update();
    }
  }, [swiper]);

  const data = [
    {
      thumbnail:
        "https://blogger.googleusercontent.com/img/a/AVvXsEj52wQz8F4DXM0b_j_6U8Xf7li5g4ZfWLyl3UrJ2-PMm-XEOrtBdYd56c3B2BgziCQSJuh8gXncBsdGXh6pHGb6ULLBiEi-0wG0VH32IbTefFqTElKHQPnPkVGgHfoJEKDXrtcVG8Tgs40hB8P8JxESex3NdvWyoxszMiV6XOrJqOD_2Ceo8VN2vJXZk80=w267-h400",
    },
    {
      thumbnail:
        "https://blogger.googleusercontent.com/img/a/AVvXsEhwX6ETJ66sZwXjMOagWcGuD4wJI1F6V9iOAKyT25r16U-zUCEfQXWTrups8Cds5NlDlh7_k61WzJDSk26D5n3hXfAimIqamZWmRyOs6h-B4tMOS3-oS9N_XYG_HDr-nK6jETlukKJ2N1lOJoQJ6qXnIdyx2SObFdMicpKiS_dSb_3oVNpR-y8Mxp7apIc=w267-h400",
    },
    {
      thumbnail:
        "https://blogger.googleusercontent.com/img/a/AVvXsEi3Fi2aaDOSC4i0Jl38xrkKgKp6naRK78N8VXyq1QAywrc2Q2eWXgjDJJDGjnCa-52CN29c6eocIXFufH91XG9rJuy2QjWzQZfgC3WuaiupDOnk1izhaaoOMOZov5_OyC7Hu6W2llcVsJ53eTV2qVcC5hUUcQn4AOFrCYVJfVoj82L-RhRR_dzWgbggRTA=w267-h400",
    },
    {
      thumbnail:
        "https://blogger.googleusercontent.com/img/a/AVvXsEho0OqcO45NqkVrzKVTR98rM7TJKQg6AsZ7N7c34MEj1gazzSLzlJRj1P8ea4GA4oTpza8wlDfxH4eC1PEx3OjuFpOivL1PuNEwmJRIqyNvoKGhuf-b6LmwqIN11M5F_FrJR2TRFRQLxFgnGtW6fyTo-PS-BBU9UbrjqbznJAuukmxx-anJuW1Z7hVWHjw=w253-h400",
    },
    {
      thumbnail:
        "https://blogger.googleusercontent.com/img/a/AVvXsEilOTXL2lc83bHQR_rmaU9qpldzU1-aI3ZQ30qJGaNaZo6MckZtzvE7DLiNKtPJUQGMuA05hROIwBa18FTKlJyrkULP27HM3jkcK80HhTX-pPMP6N0kI-e4ngCXVR5rX--Vudy5mxt6fsdzR1dY5SnIpcPcdGRNtTo6F1I_JmiGRAP4ZrP0APSq-N6Jt9c=w267-h400",
    },
    {
      thumbnail:
        "https://blogger.googleusercontent.com/img/a/AVvXsEh3gWIMpS18xMmYGSV6va5_OJm8PDk05j0cFgVgjnmVuSGNSOk0L5x03DL4FXiGG7l-G8kbJMoYLlXpI1o1SaYZopf38S6l2rNE7bj09YRVCP179DmUFAcCi28MW_O-8idndMPS-387KwcCP4lSUFLqKG10U45wd6_AKATdC3MToU0zsjP2OgtiCVQaXIY=w267-h400",
    },
    {
      thumbnail:
        "https://blogger.googleusercontent.com/img/a/AVvXsEhrBdxgV-W-N6nSOXehd4rBuNs4eQ65WLOOYIQoeo06E7txn5tsTCniyVencLdn0vG9Oi6tZfpTT_Ay4LYR5GhPa78V0UJUPm8tqJ0mHnjMZCoV-lZyonsMNhqncfTRwRrQcC3wZSfo7XZVlcwh88QaPyukHr3LA6A04ZGBQE1dbHWMPW9SgbFZZJE1rmQ=w250-h400",
    },
    {
      thumbnail:
        "https://blogger.googleusercontent.com/img/a/AVvXsEhXXTflnedxR0Cly_vNPY_kB8BwUKjDkaNTPUitoPIbXR9IfCzoi0IijkoP2cSxYPEevjV5y7wit7CCynpl6GFC3UrX5WnPXkKH09K38Cs-FQ97VVTG_6cXSELa-MYtZLceCY-IpM0mzwfNGSPwnRx_ksaRtCl3pLSLfxTguS0VnxKuFEyXNts2OjVbQF0=w267-h400",
    },

    {
      thumbnail:
        "https://blogger.googleusercontent.com/img/a/AVvXsEh3VJMv5UH2MUnPCRfnlzY4eeuqhaaeGnnyywaJ-vlL8ZqYjcKofKP-me-JR30CAGTkZFmqk6JTEvt6mzmKAHnYwtntjhcHAcv-MpgD_7HZBV-CT0Vd3OuEsOsOl4rF43RH9nyr7Pc5auU7KyuLON9tUUva1sAPqrLcmH4rDrthxEaLVfCnKjQeBxfq7C0=w267-h400",
    },
    {
      thumbnail:
        "https://blogger.googleusercontent.com/img/a/AVvXsEgOJndXevRglkur4cSAle_2ekqaWHtf8f0DCgqhFKYQxHIgp3WzsWxQN3tDa1h314OqlCaNLKFABvw9ZEqz4yS47IkQJlVyRS3Gb738YKXwMUSgoL24OVMMdpOXWxX5zMbwwbp1-eTwMu94olk7BT94N1PGzlSnfKlFWu7CdNRIkBT4EbF6lo6v3BVRmZk=w270-h400",
    },
    {
      thumbnail:
        "https://blogger.googleusercontent.com/img/a/AVvXsEgIwjSStpcoAnX5po3AC_IwNf5sty3Ceex2Hwywoor67MPWF2vHm2-ZIUENFPs9dBurUp2tigK3Be1agkNxg_P8WMXOByRA5S10PLduuVtb27q1ikmGXUkCftnxUYBz7nc3ZmZUvIwHCPKRzDkWdmdltEVHtvNp8bR-NFnCEYiBSUMpYt8_a5oRTMt2FYU=w267-h400",
    },
    {
      thumbnail:
        "https://blogger.googleusercontent.com/img/a/AVvXsEgNq32tB4_sme1_nBAwPx5tqv4EX8MT0E5UMiXo04ABPF_lVZQzFFR581heDW_Ki4DmkecB9Rk77VTFNdpunVp7U76XxJG9902LXrQOqi8bZDCjoWcc3hbayvcrq5hCIZD8j_pyDAVY8_uhJeYYPsBL4JfoyMkMVDecni6Bv-JVR_G_cq221vM4x13imK0=w253-h400",
    },

    {
      thumbnail:
        "https://blogger.googleusercontent.com/img/a/AVvXsEg7VXwu7SgeuMFj1nsljqS8p8MkCAk-qulnNjobENYbdzCtMU_XDjLHpTfNe2W1tQFDudkmjLa33HclMYk6dIo_vpRaiqU5jAgyt3wt-KmrRI0AYBhFVaLKV8PAidHfO6GhPAVVtfduLvvW6ce-kAPoIqa6O2ZaZnBooF4FAmw4_PQGWQhJh1758DoruGg=w267-h400",
    },
    {
      thumbnail:
        "https://blogger.googleusercontent.com/img/a/AVvXsEjybGfXXahKRiCKlO8gAQvSDOfvVIbuHRoEUrHfwUFQY4Wxk4uu2CcEUfaJWfFC4vF44otmxvn3zDQK0NkQuBGESYvBhr5J8eO5d45ndOUnS5VwCSyS8YdE5MtvFpKalAEvqqwV2YHEgxVgegZJK8ORrF8nWhHlotci8RhPyCImYWnyfUx-fbEsjkLfbhQ=w253-h400",
    },
    {
      thumbnail:
        "https://blogger.googleusercontent.com/img/a/AVvXsEi4ObOVRlxjCPGKClkNNOTFfvAwwNpH4Hk58ZOv1KUyjwWSV-TAS_1DwMppsKarRdPqY5lqr_BXiVIM70bzYov6ezECgJtek_bev3kHNussLfAoL1wcE-3ArniK6bQuW9eLdCad7OoC0c8PMXzaKgLCvv3_0oLazn3_CbkPr3EiALfRHoX8G-jfw7I83D8=w253-h400",
    },
  ];

  return (
    <div
      className=" w-screen  bg-blue-600"
      id="gallery"
      style={{
        backgroundImage: `url(${"https://blogger.googleusercontent.com/img/a/AVvXsEhwX6ETJ66sZwXjMOagWcGuD4wJI1F6V9iOAKyT25r16U-zUCEfQXWTrups8Cds5NlDlh7_k61WzJDSk26D5n3hXfAimIqamZWmRyOs6h-B4tMOS3-oS9N_XYG_HDr-nK6jETlukKJ2N1lOJoQJ6qXnIdyx2SObFdMicpKiS_dSb_3oVNpR-y8Mxp7apIc=s16000"})`,
        backgroundSize: "cover",
        backgroundAttachment: "fixed",
        backgroundPosition: "center",
        backgroundBlendMode: "multiply",
      }}
    >
      <div className="w-full flex flex-col items-center backdrop-blur py-20">
        <div
          className="font-base black flex  gotham md:text-5xl text-3xl pb-8 text-white"
          data-aos="fade-up"
        >
          Gallery
        </div>
        <div className="md:max-w-[1020px] w-full">
          <div className="w-full  flex">
            <div
              className="swiper-button hidden md:flex cursor-pointer flex items-center "
              ref={nextRef}
              style={{
                height: 300,
              }}
            >
              <div className="h-10 w-10 flex text-white hover:text-blue-500 hover:border-blue-500 hover:bg-blue-500 hover:bg-opacity-30 items-center justify-center bg-white rounded-full mx-2 bg-opacity-20 border border-white border-opacity-30">
                <BsChevronLeft />
              </div>
            </div>
            <Swiper
              slidesPerView={2}
              spaceBetween={20}
              autoplay={{ delay: 3000 }}
              loop={true}
              updateOnWindowResize
              onSwiper={setSwiper}
              navigation={{
                prevEl: prevRef?.current,
                nextEl: nextRef?.current,
              }}
              observer
              observerParents
              breakpoints={{
                640: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 4,
                  spaceBetween: 10,
                },
              }}
            >
              {data.map((item, i) => (
                <SwiperSlide key={i}>
                  <div className=" group flex flex-col  relative overflow-hidden  rounded-lg">
                    <div className="">
                      <div>
                        <img
                          alt="rg_images"
                          src={item.thumbnail} // use normal <img> attributes as props
                          style={{
                            width: "100%",
                            height: "100%",
                          }}
                          className="object-cover cursor-pointer  pic"
                        />
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            <div
              className="swiper-button hidden md:flex cursor-pointer  items-center"
              ref={prevRef}
              style={{
                height: 300,
              }}
            >
              <div className="h-10 w-10  hover:text-blue-500 hover:border-blue-500 hover:bg-blue-500 hover:bg-opacity-30 flex text-white items-center justify-center bg-white rounded-full mx-2 bg-opacity-20 border border-white border-opacity-30">
                <BsChevronRight />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gallery;
